import './css/contactSection.scss'
import scribble from '../images/scribble.svg'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

async function sendTextMessage ( name, phone, email, msg ) {

    const url = "https://wamarketingpro.com/api/user/v2/send_message"; // Replace with your domain endpoint

    const body = {
        client_id:
            "eyJ1aWQiOiIzT0FrNkVHOGVtNjdDMTh0QjhWazAxTXlEU2tTcUg4eCIsImNsaWVudF9pZCI6IlNvdXJhdiJ9", // Client ID here
        mobile: `919835888269`,
        text: `**Leads from Health Pioneers**\n Name: ${name} \n Email: ${email} \n Phone: ${phone} \n Message: ${msg}`,
    };

    const token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIzT0FrNkVHOGVtNjdDMTh0QjhWazAxTXlEU2tTcUg4eCIsInJvbGUiOiJ1c2VyIiwiaWF0IjoxNzAxMjU3MjAwfQ.Srg4hdP8hNY_xFfaFOO6rdYutIHVBXDGpJXVvaG-Ki0"; // Your API keys here

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error("Request failed");
        }

        const data = await response.json();
        console.log(data); // Handle the response data as per your requirements
    } catch (error) {
        console.error(error);
    }
}

export default function ContactSection () {
    const navigate=useNavigate();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name && email && phone && msg) {
            await sendTextMessage(name, phone, email, msg);
            setName("");
            setPhone("");
            setEmail("");
            setMsg("");
            navigate("thank-you");
        }
    };

    return (
        <>
            <section className="section-global contact-section">
                <div className="container container-2">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 mb-5 text-center col-cont">
                            <h2>
                                Have an idea?
                                <span className="txt-highlight">
                                    {" "}
                                    Let's talk
                                    <img
                                        loading="lazy"
                                        src={scribble}
                                        alt="bg_1"
                                        className="scribble"
                                    />
                                </span>
                            </h2>
                            <p className="txt-1 mb-16">
                                One of the best industry service providers with
                                top tier talented individuals.
                            </p>
                        </div>
                    </div>
                    <div className="row gy-5">
                        <div className="col-lg-6 ">
                            <iframe
                                loading="lazy"
                                className="h-100 w-100 m-auto home_map"
                                title="Branding Pioneers"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.177652286824!2d77.03991471500397!3d28.413895882504505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2286cb252607%3A0x5b5af71fbe24d212!2sBranding%20Pioneers!5e0!3m2!1sen!2sin!4v1677842597333!5m2!1sen!2sin"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                            {/* style="filter: ;" */}
                        </div>
                        <div className="col-lg-6">
                            <h2 className="mb-5 display-2">
                                Conatct our sales and support team
                            </h2>
                            <form
                                className="row g-3"
                                onSubmit={(e)=>handleSubmit(e)}>
                                <div className="col-md-12">
                                    <label className="form-label">Name*</label>
                                    <input
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Jhon Doe"
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Email</label>
                                    <input
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="jhondoe@email.com"
                                    />
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Phone*</label>
                                    <input
                                        value={phone}
                                        onChange={(e) =>{
                                                if(!isNaN(e.target.value)&&phone.length<10){
                                                    console.log("Now")
                                                    setPhone(e.target.value)
                                                }
                                            }
                                        }
                                        type="tel"
                                        name="phone"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        value={msg}
                                        onChange={(e) =>
                                            setMsg(e.target.value)
                                        }
                                        className="form-control"
                                        name="message"
                                        rows="4"
                                        placeholder="How can we help you?"
                                        ></textarea>
                                </div>
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}