import Helmet from 'react-helmet'
import './style.scss'
import Hero from '../../components/hero'
import { BsCheckCircleFill } from "react-icons/bs"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

async function sendTextMessage ( name, phone, email, msg ) {

    const url = "https://wamarketingpro.com/api/user/v2/send_message"; // Replace with your domain endpoint

    const body = {
        client_id:
            "eyJ1aWQiOiIzT0FrNkVHOGVtNjdDMTh0QjhWazAxTXlEU2tTcUg4eCIsImNsaWVudF9pZCI6IlNvdXJhdiJ9", // Client ID here
        mobile: `919789565515`,
        text: `**Leads from Health Pioneers**\n Name: ${name} \n Email: ${email} \n Phone: ${phone} \n Message: ${msg}`,
    };

    const token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIzT0FrNkVHOGVtNjdDMTh0QjhWazAxTXlEU2tTcUg4eCIsInJvbGUiOiJ1c2VyIiwiaWF0IjoxNzAxMjU3MjAwfQ.Srg4hdP8hNY_xFfaFOO6rdYutIHVBXDGpJXVvaG-Ki0"; // Your API keys here

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error("Request failed");
        }

        const data = await response.json();
        console.log(data); // Handle the response data as per your requirements
    } catch (error) {
        console.error(error);
    }
}

export default function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact</title>
            </Helmet>
            <div className="contact-container">
                <Hero title="We are always here to ensure customer satisfaction" />
                <ContactSection />
                <ContactLinks />
            </div>
        </>
    )
}

const ContactSection = () => {
    const navigate=useNavigate();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name && email && phone && msg) {
            await sendTextMessage(name, phone, email, msg);
            setName("");
            setPhone("");
            setEmail("");
            setMsg("");
            navigate("/thank-you");
        }
    };

    const features_data = [
        "Seamless, comprehensive, and cost-effective",
        "Connect with your customers",
        "Streamline your operations"
    ]

    return (
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-5">
                        <div className="col-lg-6">
                            <h2 className='mb-16'>Conatct our sales and support team</h2>
                            <p className="txt-1">We cut through the clutter to uncover new opportunities, and always ensure customer satisfaction</p>
                            {features_data.map((e, i) => <div key={i} className="txt-2 color-1 fw-500 mb-8 gap-2 d-flex align-items-center">
                                <BsCheckCircleFill fill='#0FA958' fontSize={24} />
                                {e}
                            </div>)}
                        </div>
                        <div className="col-lg-6">
                        <form
                                className="row g-3"
                                onSubmit={(e)=>handleSubmit(e)}>
                                <div className="col-md-12">
                                    <label className="form-label">Name*</label>
                                    <input
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Jhon Doe"
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Email</label>
                                    <input
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="jhondoe@email.com"
                                    />
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Phone*</label>
                                    <input
                                        value={phone}
                                        onChange={(e) =>{
                                                if(!isNaN(e.target.value)&&phone.length<10){
                                                    console.log("Now")
                                                    setPhone(e.target.value)
                                                }
                                            }
                                        }
                                        type="tel"
                                        name="phone"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        value={msg}
                                        onChange={(e) =>
                                            setMsg(e.target.value)
                                        }
                                        className="form-control"
                                        name="message"
                                        rows="4"
                                        placeholder="How can we help you?"
                                        ></textarea>
                                </div>
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const ContactLinks = () => {
    const contactLinkData = [
        {
            title: "Email",
            text: "brandingpioneers@gmail.com",
            icoUrl: "contact_ico1.svg",
            link: "mailto:stact.team@email.com"
        },
        {
            title: "Phone",
            text: "+91 8377009395",
            icoUrl: "contact_ico2.svg",
            link: "tel:+911234567890"
        },
        {
            title: "Headquarters",
            text: "Branding Pioneers 750, 3rd Floor, Udyog Vihar, Phase V, Sector - 19, Gurugram - 122016",
            icoUrl: "contact_ico3.svg",
            link: "/"
        }
    ]

    return (
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container container-2">
                    <div className="row gy-4">
                        {
                            contactLinkData.map((e, i) =>
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <a href={e.link} className="contact-link">
                                        <div className="contact-link-container d-flex align-items-center">
                                            <img loading='lazy' src={require(`../../images/${e.icoUrl}`)} height="50" alt={e.title} />
                                            <div className='ms-3'>
                                                <div className="txt-2 fw-500 color-1">{e.title}</div>
                                                <div className="txt-3">{e.text}</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )}
                    </div>
                </div>
            </section>
        </>
    )
}
