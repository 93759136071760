import { Link } from "react-router-dom";
import { TbArrowNarrowRight, TbBrandMeta } from "react-icons/tb";
import TestimonialCards from "../../components/testimonialCards";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { SiMicrosoftbing } from "react-icons/si";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { MdSettingsSuggest } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
import { BsGoogle } from "react-icons/bs";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

const counter_data = [
  {
    count: "8+",
    text: "Years",
  },
  {
    count: "200+",
    text: "Hospitals/Clinics",
  },
  {
    count: "70+",
    text: "Members Team",
  },
  {
    count: "200%",
    text: "Avg ROI",
  },
];

export default function AestheticClinics() {
  return (
    <>
      <Helmet>
        <title>Aesthetic Clinics at Health Pioneers</title>
      </Helmet>
      <div className="project-detail-container">
        <Hero title="Digital Signage Solution" />
        <Description />
        <Stats data={counter_data} />
        <FAQSection />
        <Testimonials />
        <OurPartners />
      </div>
    </>
  );
}

function Stats({ data }) {
  return (
    <>
      <section className="counter-section dark">
        <div className="container">
          <div className="row mx-1" style={{ justifyContent: "center" }}>
            {data.map((e, i) => (
              <div
                className="col-6 col-md-3"
                key={i}
                style={{ textAlign: "center" }}
              >
                <h2 className="display-1">{e.count}</h2>
                <p className="txt-1">{e.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

const TableData = () => {
  return (
    <div class="container py-5">
      <div className="row tableDiv">
        <div className="section-tag mb-8 text-center mb-3">
          The Journey of a Patient
        </div>
        <div
          className="col-md-12 p-0"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
        >
          <div class="brze_statistike">
            <table className="table table-striped table-bordered text-center m-0">
              <thead>
                <tr>
                  <th scope="col" className="handleResponsive">
                    #
                  </th>
                  <th scope="col">Service</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <MdSettingsSuggest color="#e94057" />
                  </th>
                  <td>
                    <span>SEO</span>
                  </td>
                  <td>
                    Elevating the online presence to reach the right audience at
                    the right time.
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <BiBookContent />
                  </th>
                  <td>
                    <span>Content Marketing</span>
                  </td>
                  <td>
                    Creating patient-focused content, highlighting treatment
                    benefits.
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <BsGoogle color="#e94057" />
                  </th>
                  <td>
                    <span>Social Media Marketing</span>
                  </td>
                  <td>
                    Building credibility through patient testimonials,
                    before-after transformations, and expert sessions.
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <RiMoneyDollarCircleFill color="#e94057" />
                  </th>
                  <td>
                    <span>Pay-Per-Click</span>
                  </td>
                  <td>Targeted campaigns to achieve maximum ROI.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function OurPartners() {
  return (
    <>
      <section className="ourParnersSection">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
              <div className="section-tag mb-8">Our Partners</div>
              {/* <h2>Our Technology Partners</h2> */}
            </div>
          </div>
          <div className="d-flex gap-5 align-items-center justify-content-center ourParners text-center flex-wrap">
            <div>
              <FaGoogle className="google" fontSize={48} />
              <p>Google Partners</p>
            </div>
            <div>
              <TbBrandMeta className="meta" fontSize={48} />
              <p>Meta Partners</p>
            </div>
            <div>
              <FaInstagram className="instagram" fontSize={48} />
              <p>Instagram Partners</p>
            </div>
            <div>
              <SiMicrosoftbing className="bing" fontSize={48} />
              <p>Bing Partners</p>
            </div>
            <div>
              <BsYoutube className="youtube" fontSize={48} />
              <p>Youtube Ads</p>
            </div>
            <div>
              <BsFacebook className="facebook" fontSize={48} />
              <p>Facebook Ads</p>
            </div>
            <div>
              <BsTwitter className="twitter" fontSize={48} />
              <p>Twitter Ads</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function FAQSection() {
  const FAQ_data = [
    {
      title: " How does HealthPioneers' strategy differ for aesthetic clinics?",
      text: `We tailor our strategies based on the unique treatments, services, and clientele of aesthetic clinics, ensuring maximum reach and relevance.`,
    },
    {
      title:
        "Can HealthPioneers handle online reputation management for aesthetic clinics?",
      text: "Absolutely! We manage and elevate your clinic's online reputation, ensuring positive patient testimonials and feedback are highlighted.",
    },
  ];

  return (
    <>
      <section className="section-global bg-shade-green" id="FAQs">
        <div className="container">
          <div className="row">
            <div className="row mb-40">
              <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                <div className="section-tag mb-8">FAQs</div>
                <h2>Do You Have Questions?</h2>
              </div>
            </div>
          </div>
          <div className="container container-2">
            <div className="row">
              <div className="col-12">
                <Accordion alwaysOpen flush>
                  {FAQ_data.map((e, i) => (
                    <Accordion.Item eventKey={i} key={i}>
                      <Accordion.Header>{e.title}</Accordion.Header>
                      <Accordion.Body>{e.text}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const Hero = () => {
  return (
    <>
      <section className="section-global no-border bg-shade-green hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Pioneering Digital Solutions for Aesthetic Clinics</h1>
            </div>
            <div className="col-lg-6">
              <p className="txt-1 mb-20" style={{ lineHeight: "2" }}>
                HealthPioneers' Specialized Approach for Aesthetic Clinics
                Aesthetic clinics cater to a discerning clientele that values
                precision, and discretion. HealthPioneers appreciates
                these nuances and crafts digital marketing strategies that are
                both compelling and sensitive to the unique needs of aesthetic
                treatments and services.
              </p>
              <Link to="/" className="btn btn-outline-primary btn-lg">
                Know more
              </Link>
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div className="col-12">
              <div className="hero-image-container">
                <img
                  style={{
                    height: "680px",
                    borderRadius: "15px",
                    boxShadow:
                      "0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal)",
                    objectFit: "cover",
                    objectPosition: "center",
                    width: "100%",
                  }}
                  loading="lazy"
                  src={require("./images/aesthetic-clinics-banner.png")}
                  className="img-fluid"
                  alt="Stact"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <BrandSection2
                brands={brands}
                bg="bg-shade-green"
                light
                src={"OurSpeciality"}
            /> */}
    </>
  );
};

const Description = () => {
  return (
    <section
      className="section-global bg-shade-blue"
      style={{ paddingBottom: "50px" }}
    >
      <div className="container">
        <div className="row mb-40 gy-4">
          <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
            <div>
              <img
                loading="lazy"
                src={require("./images/aesthetic-clinics- Digital Solutions for Aesthetic Clinics.png")}
                className="img-fluid"
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="section-tag mb-8">Branding</div>
            <h2 className="mb-16">
                Why Choose HealthPioneers for Aesthetic Clinics Marketing?
            </h2>
            <p className="txt-1">
              Aesthetic clinics cater to a discerning client that values
              precision, results, and discretion. HealthPioneers appreciates
              these nuances and crafts digital marketing strategies that are
              both compelling and sensitive to the unique needs of aesthetic
              treatments and services.
            </p>
          </div>
        </div>
        <div className="row mb-40 gy-4 custom-div-handle">
          <div className="col-lg-6 align-self-center">
            <div className="section-tag mb-8">Branding</div>
            <h2 className="mb-16">Process for Aesthetic Clinics Marketing:</h2>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>Discovery:</span> Delve deep
              into the clinic's unique offerings and audience demographics.
            </p>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>Strategy Formation:</span>{" "}
              Create a customized marketing blueprint aligned with the clinic's
              goals.
            </p>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>Campaign Execution:</span>{" "}
              Launch, monitor, and refine digital campaigns to ensure maximum
              reach and engagement.
            </p>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>Feedback Loop:</span>{" "}
              Constantly gather data, adjust strategies, and enhance for
              continuous patient growth.
            </p>
          </div>
          <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
            <div>
              <img
                loading="lazy"
                src={require("./images/aesthetic-clinics-03.png")}
                className="img-fluid"
                alt="About"
              />
            </div>
          </div>
        </div>
        <TableData />
      </div>
    </section>
  );
};

function Testimonials() {
  const testimonial_data = [
    {
      text: "Branding Pioneers is hands down the best digital marketing agency we've ever worked with. Their team is incredibly knowledgeable and they were able to help us achieve our goals in a fraction of the time we expected.",
      userName: "Vandana Choudhary",
      position: "Client",
      userImgUrl: "hari.jpg",
    },
    {
      text: "Highly recommended !! Had a very good experience with Branding pioneers. They have completed the project in the said time frame and kept all the promises.",
      userName: "Nishant Raghav",
      position: "Marketing Director at ABC Hospital",
      userImgUrl: "manish.jpg",
    },
    {
      text: "The team at Branding Pioneers has been instrumental in helping us establish a strong brand identity and an effective digital marketing strategy. Their attention to detail and commitment to our success is second to none. Highly recommend!",
      userName: "Roki Sahu",
      position: "Client",
      userImgUrl: "jasleenkaur.jpg",
    },
  ];

  return (
    <>
      <div className="section-global bg-shade-blue">
        <div className="container">
          <div className="row mb-40 justify-content-between gy-4">
            <div className="col-xl-5 col-lg-5">
              <div className="section-tag mb-8 ">Customer reviews</div>
              <h2>Adopted by the most creative individuals</h2>
            </div>
            <div className="col d-flex align-self-center">
              <Link
                to="about/testimonials"
                className="btn btn-outline btn-arrow ms-lg-auto"
              >
                See all reviews
                <TbArrowNarrowRight fontSize={24} />
              </Link>
            </div>
          </div>
          <div className="row">
            <TestimonialCards data={testimonial_data} />
          </div>
        </div>
      </div>
    </>
  );
}
