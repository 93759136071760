// import Hero from "../../components/hero";

import { Link } from "react-router-dom";
import TestimonialCards from "../../components/testimonialCards";
import { TbArrowNarrowRight, TbBrandMeta } from "react-icons/tb";
import "./style.scss";
import Stats from "../../components/stats";
import { Accordion } from "react-bootstrap";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { SiMicrosoftbing } from "react-icons/si";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { MdSettingsSuggest } from "react-icons/md"
import { BiBookContent } from "react-icons/bi";
import { BsGoogle } from "react-icons/bs";
import {FaHospitalSymbol} from "react-icons/fa"

export default function Hospitals() {

    const counter_data = [
        {
            count: "8+",
            text: "Years",
        },
        {
            count: "200+",
            text: "Hospitals/Clinics",
        },
        {
            count: "70+",
            text: "Members Team",
        },
        {
            count: "200%",
            text: "Avg ROI",
        },
    ];
  return (
      <>
          <div className="project-detail-container">
              <Hero title="Hospitals" />
              <Description />
              {/* <CaseStudy /> */}
              <Stats data={counter_data} />
              <FAQSection />
              <Testimonials />
              <OurPartners />
          </div>
      </>
  );
}

const TableData=() => {
    return (
        <div class="container py-5">
            <div
                className="row tableDiv">
                <div className="section-tag mb-8 text-center mb-3">
                    Branding
                </div>
                <div
                    className="col-md-12 p-0"
                    style={{
                        boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}>
                    <div class="brze_statistike">
                        <table className="table table-striped table-bordered text-center m-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="handleResponsive">
                                        #
                                    </th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Highlight</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <MdSettingsSuggest color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Hospital SEO</span>
                                    </td>
                                    <td>
                                        Enhancing online visibility through
                                        search engine optimization.
                                    </td>
                                    <td>60% increase in organic traffic</td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <BiBookContent />
                                    </th>
                                    <td>
                                        <span>Content Strategy</span>
                                    </td>
                                    <td>
                                        Creating resonating content for patient
                                        engagement.
                                    </td>
                                    <td>Boost patient trust & loyalty</td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <BsGoogle color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Social Media Campaigns</span>
                                    </td>
                                    <td>
                                        Engaging campaigns tailored for hospital
                                        audiences.
                                    </td>
                                    <td>40% increased engagement rate</td>
                                </tr>
                                <tr>
                                    <th className="handleResponsive">
                                        <FaHospitalSymbol />
                                    </th>
                                    <td>
                                        <span>PPC for Hospitals</span>
                                    </td>
                                    <td>
                                        Targeted ad strategies, ensuring your
                                        hospital gets noticed.
                                    </td>
                                    <td>
                                        Maximize ROI with cost-effective
                                        campaigns
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

function OurPartners() {
    return (
        <>
            <section className="ourParnersSection">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Our Partners</div>
                            {/* <h2>Our Technology Partners</h2> */}
                        </div>
                    </div>
                    <div className="d-flex gap-5 align-items-center justify-content-center ourParners text-center flex-wrap">
                        <div>
                            <FaGoogle className="google" fontSize={48} />
                            <p>Google Partners</p>
                        </div>
                        <div>
                            <TbBrandMeta className="meta" fontSize={48} />
                            <p>Meta Partners</p>
                        </div>
                        <div>
                            <FaInstagram className="instagram" fontSize={48} />
                            <p>Instagram Partners</p>
                        </div>
                        <div>
                            <SiMicrosoftbing className="bing" fontSize={48} />
                            <p>Bing Partners</p>
                        </div>
                        <div>
                            <BsYoutube className="youtube" fontSize={48} />
                            <p>Youtube Ads</p>
                        </div>
                        <div>
                            <BsFacebook className="facebook" fontSize={48} />
                            <p>Facebook Ads</p>
                        </div>
                        <div>
                            <BsTwitter className="twitter" fontSize={48} />
                            <p>Twitter Ads</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function FAQSection() {
    const FAQ_data = [
        {
            title: "What sets your healthcare marketing agency apart from others?",
            text: `At Branding Pioneers, we differentiate ourselves by providing customized solutions tailored to the unique needs and goals of each of our clients. Our team of healthcare marketing experts has extensive experience working in the industry, which allows us to develop innovative and effective strategies that drive results. We prioritize customer satisfaction, building long-lasting relationships based on trust, transparency, and results. Additionally, we stay on top of the latest trends and technologies in the industry, which enables us to deliver cutting-edge solutions to our clients.`,
        },
        {
            title: "How long does it take to see results from your healthcare marketing campaigns?",
            text: "The timeline for seeing results from our healthcare marketing campaigns varies depending on several factors, including your business goals, the type of services you require, and the size of your healthcare business. However, we typically see measurable results within three to six months of launching a campaign. We will work with you to set realistic expectations and develop a timeline that aligns with your business objectives.",
        },
        {
            title: "How do you measure the success of your healthcare marketing campaigns?",
            text: "We measure the success of our healthcare marketing campaigns using a range of metrics, including website traffic, conversion rates, engagement rates, social media reach, and return on investment (ROI). We use sophisticated tools and technologies to monitor and track these metrics, which enables us to make data-driven decisions and continuously optimize your campaigns to achieve the best possible results.",
        },
        {
            title: "Can you guarantee specific results for my healthcare business?",
            text: "While we cannot guarantee specific results, we can promise that we will work tirelessly to deliver the best possible outcomes for your healthcare business. Our team of healthcare marketing experts will collaborate with you to develop a customized strategy that aligns with your business goals and objectives. We will regularly measure and analyze the performance of your campaigns to make data-driven decisions and adjust our approach as necessary to optimize your results.",
        },
    ];

    return (
        <>
            <section className="section-global bg-shade-green" id="FAQs">
                <div className="container">
                    <div className="row">
                        <div className="row mb-40">
                            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                                <div className="section-tag mb-8">FAQs</div>
                                <h2>Do You Have Questions?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container container-2">
                        <div className="row">
                            <div className="col-12">
                                <Accordion alwaysOpen flush>
                                    {FAQ_data.map((e, i) => (
                                        <Accordion.Item eventKey={i} key={i}>
                                            <Accordion.Header>
                                                {e.title}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {e.text}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const Hero = () => {
    return (
        <>
            <section className="section-global no-border bg-shade-green hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>
                                Elevate Your Hospital's Digital Journey with
                                HealthPioneers
                            </h1>
                        </div>
                        <div className="col-lg-6">
                            <p
                                className="txt-1 mb-20"
                                style={{ lineHeight: "2" }}>
                                The digital realm is ever-evolving, and with it,
                                the opportunities for hospitals to reach a wider
                                audience. As a leading healthcare digital
                                marketing agency, HealthPioneers brings bespoke
                                solutions tailored exclusively for healthcare
                                institutions.
                            </p>
                            <Link
                                to="/"
                                className="btn btn-outline-primary btn-lg">
                                Know more
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "50px" }}>
                        <div className="col-12">
                            <div className="hero-image-container">
                                <img
                                    style={{
                                        height: "680px",
                                        borderRadius: "15px",
                                        boxShadow:
                                            "0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal)",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        width: "100%",
                                    }}
                                    loading="lazy"
                                    src={require("./images/Elevate Your Hospital's Digital Journey with HealthPioneers_11zon.webp")}
                                    className="img-fluid"
                                    alt="Stact"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <BrandSection2
                brands={brands}
                bg="bg-shade-green"
                light
                src={"OurSpeciality"}
            /> */}
        </>
    );
};

const Description = () => {
    return (
        <section
            className="section-global bg-shade-blue"
            style={{ paddingBottom: "50px" }}>
            <div className="container">
                <div className="row mb-40 gy-4">
                    <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                        <div>
                            <img
                                loading="lazy"
                                src={require("./images/Why Hospitals Choose HealthPioneers.png")}
                                className="img-fluid"
                                alt="About"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="section-tag mb-8">Branding</div>
                        <h2 className="mb-16">
                            Why Hospitals Choose HealthPioneers?
                        </h2>
                        <p className="txt-1">
                            Tailored Strategies: Understand your institution's
                            unique challenges and goals. Our strategies are
                            custom-made, ensuring you get the best ROI.
                        </p>
                        <p className="txt-1">
                            Domain Expertise: With a solid background in
                            healthcare, our strategies resonate with your
                            audience, ensuring maximum engagement.
                        </p>
                        <p className="txt-1">
                            Data-Driven Approach: Using advanced analytics and
                            tools, we drive decisions that lead to tangible
                            results.
                        </p>
                        <p className="txt-1">
                            End-to-End Service: From strategy formulation to
                            execution, we've got you covered. Infographic Idea:
                            Journey of a hospital in the digital domain - from
                            initial presence to dominant leadership with
                            HealthPioneers.
                        </p>
                        {/* <ul className="txt-1">
                            <li>
                                Tailored Strategies: Understand your
                                institution's unique challenges and goals. Our
                                strategies are custom-made, ensuring you get the
                                best ROI.
                            </li>
                            <li>
                                Domain Expertise: With a solid background in
                                healthcare, our strategies resonate with your
                                audience, ensuring maximum engagement.
                            </li>
                            <li>
                                Data-Driven Approach: Using advanced analytics
                                and tools, we drive decisions that lead to
                                tangible results.
                            </li>
                            <li>
                                End-to-End Service: From strategy formulation to
                                execution, we've got you covered. Infographic
                                Idea: Journey of a hospital in the digital
                                domain - from initial presence to dominant
                                leadership with HealthPioneers.
                            </li>
                        </ul> */}
                    </div>
                </div>
                <TableData />
            </div>
        </section>
    );
};

function Testimonials() {
    const testimonial_data = [
        {
            text: "Branding Pioneers is hands down the best digital marketing agency we've ever worked with. Their team is incredibly knowledgeable and they were able to help us achieve our goals in a fraction of the time we expected.",
            userName: "Vandana Choudhary",
            position: "Client",
            userImgUrl: "hari.jpg",
        },
        {
            text: "Highly recommended !! Had a very good experience with Branding pioneers. They have completed the project in the said time frame and kept all the promises.",
            userName: "Nishant Raghav",
            position: "Marketing Director at ABC Hospital",
            userImgUrl: "manish.jpg",
        },
        {
            text: "The team at Branding Pioneers has been instrumental in helping us establish a strong brand identity and an effective digital marketing strategy. Their attention to detail and commitment to our success is second to none. Highly recommend!",
            userName: "Roki Sahu",
            position: "Client",
            userImgUrl: "jasleenkaur.jpg",
        },
    ];

    return (
        <>
            <div className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8 ">
                                Customer reviews
                            </div>
                            <h2>Adopted by the most creative individuals</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link
                                to="about/testimonials"
                                className="btn btn-outline btn-arrow ms-lg-auto">
                                See all reviews
                                <TbArrowNarrowRight fontSize={24} />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} />
                    </div>
                </div>
            </div>
        </>
    );
}


// const CaseStudy = () => {
//   return (
//     <>
//       <section className='section-global case-study'>
//         <div className="container container-2">
//           <div className="row">
//             <div className="col-12 text-center mb-20">
//               {/* <img loading='lazy' src="" className="img-fluid mb-20" alt="CaseStudy" /> */}
//             </div>
//             <div className="col-12">
//               <div className="case-study-section">

//                 <br />

//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   )
// }