// import Hero from "../../components/hero";
import { Link } from "react-router-dom";
import TestimonialCards from "../../components/testimonialCards";
import { TbArrowNarrowRight, TbBrandMeta } from "react-icons/tb";
import "./style.scss";
import Stats from "../../components/stats";
import { Accordion } from "react-bootstrap";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { SiMicrosoftbing } from "react-icons/si";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { MdSettingsSuggest } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
import { TbSocial } from "react-icons/tb";
import { FaHospitalSymbol } from "react-icons/fa";
import { Helmet } from "react-helmet";

export default function DiagnosticCentres() {
    const counter_data = [
        {
            count: "8+",
            text: "Years",
        },
        {
            count: "200+",
            text: "Hospitals/Clinics",
        },
        {
            count: "70+",
            text: "Members Team",
        },
        {
            count: "200%",
            text: "Avg ROI",
        },
    ];
    return (
        <>
            <div className="project-detail-container">
                <Hero title="Neurosurgeons Solutions at HealthPioneers" />
                <Helmet>
                    <title>
                        Digital Marketing Solutions for Diagnostic Centres by
                        HealthPioneers
                    </title>
                </Helmet>
                <Description />
                {/* <CaseStudy /> */}
                <Stats data={counter_data} />
                <FAQSection />
                <Testimonials />
                <OurPartners />
            </div>
        </>
    );
}

const TableData = () => {
    return (
        <div class="container py-5">
            <div className="row tableDiv">
                <div className="section-tag mb-8 text-center mb-3">
                    The Orthopedician's Digital Journey
                </div>
                <div
                    className="col-md-12 p-0"
                    style={{
                        boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}>
                    <div class="brze_statistike">
                        <table className="table table-striped table-bordered text-center m-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="handleResponsive">
                                        #
                                    </th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <MdSettingsSuggest color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Enhanced Online Visibility</span>
                                    </td>
                                    <td>
                                        Engage and attract a wider patient base
                                    </td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <BiBookContent color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Patient Trust & Loyalty</span>
                                    </td>
                                    <td>
                                        Cultivate returning patients through
                                        credible online information
                                    </td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <FaHospitalSymbol color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Efficient Booking Systems</span>
                                    </td>
                                    <td>
                                        Seamless integration for appointment and
                                        test bookings
                                    </td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <TbSocial color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Data-Driven Insights</span>
                                    </td>
                                    <td>
                                        Understand patient needs and tweak
                                        services accordingly
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

function OurPartners() {
    return (
        <>
            <section className="ourParnersSection">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Our Partners</div>
                            {/* <h2>Our Technology Partners</h2> */}
                        </div>
                    </div>
                    <div className="d-flex gap-5 align-items-center justify-content-center ourParners text-center flex-wrap">
                        <div>
                            <FaGoogle className="google" fontSize={48} />
                            <p>Google Partners</p>
                        </div>
                        <div>
                            <TbBrandMeta className="meta" fontSize={48} />
                            <p>Meta Partners</p>
                        </div>
                        <div>
                            <FaInstagram className="instagram" fontSize={48} />
                            <p>Instagram Partners</p>
                        </div>
                        <div>
                            <SiMicrosoftbing className="bing" fontSize={48} />
                            <p>Bing Partners</p>
                        </div>
                        <div>
                            <BsYoutube className="youtube" fontSize={48} />
                            <p>Youtube Ads</p>
                        </div>
                        <div>
                            <BsFacebook className="facebook" fontSize={48} />
                            <p>Facebook Ads</p>
                        </div>
                        <div>
                            <BsTwitter className="twitter" fontSize={48} />
                            <p>Twitter Ads</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function FAQSection() {
    const FAQ_data = [
        {
            title: `How can digital marketing benefit my diagnostic centre?
`,
            text: `Digital marketing expands your online presence, drives more patients, and builds trust through credible online content.

`,
        },
        {
            title: `How do you measure the success of a campaign?`,
            text: `  We rely on data-driven insights, tracking tools, and feedback mechanisms to gauge the success and optimize campaigns.

`,
        },
    ];
    return (
        <>
            <section className="section-global bg-shade-green" id="FAQs">
                <div className="container">
                    <div className="row">
                        <div className="row mb-40">
                            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                                <div className="section-tag mb-8">FAQs</div>
                                <h2>Do You Have Questions?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container container-2">
                        <div className="row">
                            <div className="col-12">
                                <Accordion alwaysOpen flush>
                                    {FAQ_data.map((e, i) => (
                                        <Accordion.Item eventKey={i} key={i}>
                                            <Accordion.Header>
                                                {e.title}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {e.text}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const Hero = () => {
    return (
        <>
            <section className="section-global no-border bg-shade-green hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>
                                Empowering Diagnostic Centres with Tailored
                                Digital Marketing
                            </h1>
                        </div>
                        <div className="col-lg-6">
                            <p
                                className="txt-1 mb-20"
                                style={{ lineHeight: "2" }}>
                                In a digital age where patients turn to the
                                internet for healthcare solutions, diagnostic
                                centres can't afford to be left behind.
                                HealthPioneers bridges the gap, employing
                                strategic healthcare marketing solutions
                                tailored to elevate diagnostic centres' online
                                stature.
                            </p>
                            <Link
                                to="/"
                                className="btn btn-outline-primary btn-lg">
                                Know more
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "50px" }}>
                        <div className="col-12">
                            <div className="hero-image-container">
                                <img
                                    style={{
                                        height: "680px",
                                        borderRadius: "15px",
                                        boxShadow:
                                            "0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal)",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        width: "100%",
                                    }}
                                    loading="lazy"
                                    src={require("./images/Diagnostic Centres.jpg")}
                                    className="img-fluid"
                                    alt="Stact"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <BrandSection2
                brands={brands}
                bg="bg-shade-green"
                light
                src={"OurSpeciality"}
            /> */}
        </>
    );
};

const Description = () => {
    return (
        <section
            className="section-global bg-shade-blue"
            style={{ paddingBottom: "50px" }}>
            <div className="container">
                <div className="row mb-40 gy-4">
                    <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                        <div>
                            <img
                                loading="lazy"
                                src={require("./images/Why Choose HealthPioneers for Your Diagnostic Centres.png")}
                                className="img-fluid"
                                alt="About"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="section-tag mb-8">Branding</div>
                        <h2 className="mb-16">
                            Why Choose HealthPioneers for Your Diagnostic
                            Centres?
                        </h2>
                        <p className="txt-1">
                            With an intrinsic understanding of healthcare
                            intricacies, HealthPioneers offers bespoke digital
                            marketing solutions for diagnostic centres. Our
                            methods aren't generic; they're crafted keeping in
                            mind the diagnostic sector's unique demands.
                        </p>
                    </div>
                </div>
                <div className="row mb-40 gy-4">
                    <div className="col-lg-6 align-self-center">
                        <h2 className="mb-16">
                            Diagnostic Centres Process at HealthPioneers
                        </h2>
                        <p className="txt-1">
                            <span style={{ fontWeight: 600 }}>
                                Understanding:
                            </span>
                            &nbsp;Grasping the unique offerings and needs of the
                            diagnostic centre.
                        </p>
                        <p className="txt-1">
                            <span style={{ fontWeight: 600 }}>
                                Strategizing:
                            </span>
                            &nbsp;Crafting a bespoke digital marketing
                            blueprint.
                        </p>
                        <p className="txt-1">
                            <span style={{ fontWeight: 600 }}>Execution:</span>
                            &nbsp; Deploying campaigns tailored for the
                            diagnostic centre audience.
                        </p>
                        <p className="txt-1">
                            <span style={{ fontWeight: 600 }}>Monitoring:</span>
                            &nbsp;Continuous tracking, refining, and
                            optimization.
                        </p>
                    </div>
                    <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                        <div>
                            <img
                                loading="lazy"
                                src={require("./images/Diagnostic Centres Process at HealthPioneers.png")}
                                className="img-fluid"
                                alt="About"
                            />
                        </div>
                    </div>
                </div>
                <TableData />
            </div>
        </section>
    );
};

function Testimonials() {
    const testimonial_data = [
        {
            text: "Branding Pioneers is hands down the best digital marketing agency we've ever worked with. Their team is incredibly knowledgeable and they were able to help us achieve our goals in a fraction of the time we expected.",
            userName: "Vandana Choudhary",
            position: "Client",
            userImgUrl: "hari.jpg",
        },
        {
            text: "Highly recommended !! Had a very good experience with Branding pioneers. They have completed the project in the said time frame and kept all the promises.",
            userName: "Nishant Raghav",
            position: "Marketing Director at ABC Hospital",
            userImgUrl: "manish.jpg",
        },
        {
            text: "The team at Branding Pioneers has been instrumental in helping us establish a strong brand identity and an effective digital marketing strategy. Their attention to detail and commitment to our success is second to none. Highly recommend!",
            userName: "Roki Sahu",
            position: "Client",
            userImgUrl: "jasleenkaur.jpg",
        },
    ];

    return (
        <>
            <div className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8 ">
                                Customer reviews
                            </div>
                            <h2>Adopted by the most creative individuals</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link
                                to="about/testimonials"
                                className="btn btn-outline btn-arrow ms-lg-auto">
                                See all reviews
                                <TbArrowNarrowRight fontSize={24} />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} />
                    </div>
                </div>
            </div>
        </>
    );
}

const CaseStudy = () => {
    return (
        <>
            <section className="section-global case-study">
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12 text-center mb-20">
                            {/* <img loading='lazy' src="" className="img-fluid mb-20" alt="CaseStudy" /> */}
                        </div>
                        <div className="col-12">
                            <div className="case-study-section">
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
