import { Link } from "react-router-dom";
import { TbArrowNarrowRight, TbBrandMeta } from "react-icons/tb";
import TestimonialCards from "../../components/testimonialCards";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { SiMicrosoftbing } from "react-icons/si";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { MdSettingsSuggest } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
import { BsGoogle } from "react-icons/bs";
import { FaHospitalSymbol } from "react-icons/fa";
import { Accordion } from "react-bootstrap";

const counter_data = [
  {
    count: '3k+',
    text: 'SUCCESSFUL CAMPAIGNS'
  },
  {
    count: '50k+',
    text: ' QUALIFIED LEADS GENERATED'
  },
  {
    count: '300k+',
    text: 'B2B CONTACTS WITH EMAIL'
  }
]

export default function AdsAndLeadGeneration() {
  return (
      <>
          <div className="project-detail-container">
              <Hero title="Ads and Lead Generation Services for Healthcare by Branding Pioneers" />
              <Description />
              {/* <CaseStudy /> */}
              <Stats data={counter_data} />
              <FAQSection />
              <Testimonials />
              <OurPartners />
          </div>
      </>
  );
}

function Stats({ data }) {
  return (
    <>
      <section className='counter-section dark'>
        <div className="container">
          <div className="row mx-1" style={{justifyContent:"center"}}>
            {data.map((e, i) =>
              <div className="col-6 col-md-4" key={i} style={{textAlign:"center"}}>
                <h2 className="display-1">
                  {e.count}
                </h2>
                <p className='txt-1'>{e.text}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

const TableData = () => {
    return (
        <div class="container py-5">
            <div className="row tableDiv">
                <div className="section-tag mb-8 text-center mb-3">
                    The Power of Targeted Lead Generation
                </div>
                <div
                    className="col-md-12 p-0"
                    style={{
                        boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}>
                    <div class="brze_statistike">
                        <table className="table table-striped table-bordered text-center m-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="handleResponsive">
                                        #
                                    </th>
                                    <th scope="col">Lead Generation Methods</th>
                                    <th scope="col">Conversion Rate</th>
                                    <th scope="col">
                                        Application in Healthcare
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <MdSettingsSuggest color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Content Marketing</span>
                                    </td>
                                    <td>18%</td>
                                    <td>Patient Education & Awareness</td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <BiBookContent />
                                    </th>
                                    <td>
                                        <span>PPC Advertising</span>
                                    </td>
                                    <td>10%</td>
                                    <td>Targeted Service Promotion</td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <BsGoogle color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Social Media Engagement</span>
                                    </td>
                                    <td>12%</td>
                                    <td>Community Building & Engagement</td>
                                </tr>
                                <tr>
                                    <th className="handleResponsive">
                                        <FaHospitalSymbol />
                                    </th>
                                    <td>
                                        <span>PPC for Hospitals</span>
                                    </td>
                                    <td>
                                        Targeted ad strategies, ensuring your
                                        hospital gets noticed.
                                    </td>
                                    <td>
                                        Maximize ROI with cost-effective
                                        campaigns
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

function OurPartners() {
    return (
        <>
            <section className="ourParnersSection">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Our Partners</div>
                            {/* <h2>Our Technology Partners</h2> */}
                        </div>
                    </div>
                    <div className="d-flex gap-5 align-items-center justify-content-center ourParners text-center flex-wrap">
                        <div>
                            <FaGoogle className="google" fontSize={48} />
                            <p>Google Partners</p>
                        </div>
                        <div>
                            <TbBrandMeta className="meta" fontSize={48} />
                            <p>Meta Partners</p>
                        </div>
                        <div>
                            <FaInstagram className="instagram" fontSize={48} />
                            <p>Instagram Partners</p>
                        </div>
                        <div>
                            <SiMicrosoftbing className="bing" fontSize={48} />
                            <p>Bing Partners</p>
                        </div>
                        <div>
                            <BsYoutube className="youtube" fontSize={48} />
                            <p>Youtube Ads</p>
                        </div>
                        <div>
                            <BsFacebook className="facebook" fontSize={48} />
                            <p>Facebook Ads</p>
                        </div>
                        <div>
                            <BsTwitter className="twitter" fontSize={48} />
                            <p>Twitter Ads</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function FAQSection() {
    const FAQ_data = [
        {
            title: "How soon can we expect results from lead generation strategies?",
            text: `Initial results are typically visible within 3-4 months, with more substantial growth evident from 6 months onward.`,
        },
        {
            title: "Do you offer lead generation services for specialized healthcare departments?",
            text: "Absolutely! We tailor our services for diverse healthcare sectors, ensuring maximum relevance.",
        }
    ];

    return (
        <>
            <section className="section-global bg-shade-green" id="FAQs">
                <div className="container">
                    <div className="row">
                        <div className="row mb-40">
                            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                                <div className="section-tag mb-8">FAQs</div>
                                <h2>Do You Have Questions?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container container-2">
                        <div className="row">
                            <div className="col-12">
                                <Accordion alwaysOpen flush>
                                    {FAQ_data.map((e, i) => (
                                        <Accordion.Item eventKey={i} key={i}>
                                            <Accordion.Header>
                                                {e.title}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {e.text}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const Hero = () => {
    return (
        <>
            <section className="section-global no-border bg-shade-green hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>
                                Elevate Your Outreach with Focused Lead
                                Generation in Healthcare
                            </h1>
                        </div>
                        <div className="col-lg-6">
                            <p
                                className="txt-1 mb-20"
                                style={{ lineHeight: "2" }}>
                                In today's highly competitive healthcare
                                landscape, establishing a distinctive presence
                                requires more than just a generic approach. It
                                necessitates a profound comprehension of your
                                target audience, their unique needs, and their
                                evolving preferences. At the heart of this
                                intricate equation lies the critical function of
                                lead generation in healthcare, which serves as
                                the connective tissue between healthcare
                                providers and the individuals seeking their
                                services.
                            </p>
                            <Link
                                to="/"
                                className="btn btn-outline-primary btn-lg">
                                Know more
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "50px" }}>
                        <div className="col-12">
                            <div className="hero-image-container">
                                <img
                                    style={{
                                        height: "680px",
                                        borderRadius: "15px",
                                        boxShadow:
                                            "0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal)",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        width: "100%",
                                    }}
                                    loading="lazy"
                                    src={require("../../images/hero_image.jpg")}
                                    className="img-fluid"
                                    alt="Stact"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <BrandSection2
                brands={brands}
                bg="bg-shade-green"
                light
                src={"OurSpeciality"}
            /> */}
        </>
    );
};

const Description = () => {
    return (
        <section
            className="section-global bg-shade-blue"
            style={{ paddingBottom: "50px" }}>
            <div className="container">
                <div className="row mb-40 gy-4">
                    <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                        <div>
                            <img
                                loading="lazy"
                                src={require("../../images/about1.png")}
                                className="img-fluid"
                                alt="About"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="section-tag mb-8">Branding</div>
                        <h2 className="mb-16">
                            Why HealthPioneers Stands Out Among Healthcare Lead
                            Generation Companies?
                        </h2>
                        <p className="txt-1">
                            Precision, innovation, and a result-driven approach.
                            We aren’t just another of the healthcare lead
                            generation companies. We're your partners in growth.
                        </p>
                        <p className="txt-1">
                            <span style={{ fontWeight: "600" }}>
                                Sub-activity:
                            </span>{" "}
                            Patient Persona Development - Crafting detailed
                            profiles of your ideal patient. This foundation
                            ensures all lead generation activities are
                            laser-focused on conversion.
                        </p>
                        <p className="txt-1">
                            <span style={{ fontWeight: "600" }}>
                                Discovery:
                            </span>{" "}
                            Understand your core services, target audience, and
                            unique selling propositions. Strategy Formulation:
                            Develop a tailored lead generation plan, ensuring
                            high ROI. Implementation: Execute the strategy,
                            utilizing advanced tools and techniques. Analytics &
                            Feedback: Monitor real-time data, refine approaches,
                            and optimize for better results.
                        </p>

                        {/* <ul className="txt-1">
                            <li>
                                Tailored Strategies: Understand your
                                institution's unique challenges and goals. Our
                                strategies are custom-made, ensuring you get the
                                best ROI.
                            </li>
                            <li>
                                Domain Expertise: With a solid background in
                                healthcare, our strategies resonate with your
                                audience, ensuring maximum engagement.
                            </li>
                            <li>
                                Data-Driven Approach: Using advanced analytics
                                and tools, we drive decisions that lead to
                                tangible results.
                            </li>
                            <li>
                                End-to-End Service: From strategy formulation to
                                execution, we've got you covered. Infographic
                                Idea: Journey of a hospital in the digital
                                domain - from initial presence to dominant
                                leadership with HealthPioneers.
                            </li>
                        </ul> */}
                    </div>
                </div>
                <TableData />
            </div>
        </section>
    );
};

function Testimonials() {
    const testimonial_data = [
        {
            text: "Branding Pioneers is hands down the best digital marketing agency we've ever worked with. Their team is incredibly knowledgeable and they were able to help us achieve our goals in a fraction of the time we expected.",
            userName: "Vandana Choudhary",
            position: "Client",
            userImgUrl: "hari.jpg",
        },
        {
            text: "Highly recommended !! Had a very good experience with Branding pioneers. They have completed the project in the said time frame and kept all the promises.",
            userName: "Nishant Raghav",
            position: "Marketing Director at ABC Hospital",
            userImgUrl: "manish.jpg",
        },
        {
            text: "The team at Branding Pioneers has been instrumental in helping us establish a strong brand identity and an effective digital marketing strategy. Their attention to detail and commitment to our success is second to none. Highly recommend!",
            userName: "Roki Sahu",
            position: "Client",
            userImgUrl: "jasleenkaur.jpg",
        },
    ];

    return (
        <>
            <div className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8 ">
                                Customer reviews
                            </div>
                            <h2>Adopted by the most creative individuals</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link
                                to="about/testimonials"
                                className="btn btn-outline btn-arrow ms-lg-auto">
                                See all reviews
                                <TbArrowNarrowRight fontSize={24} />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} />
                    </div>
                </div>
            </div>
        </>
    );
}
