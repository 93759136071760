import { Link } from "react-router-dom";
import { TbArrowNarrowRight, TbBrandMeta } from "react-icons/tb";
import TestimonialCards from "../../components/testimonialCards";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { SiMicrosoftbing } from "react-icons/si";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { MdSettingsSuggest } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FaHospitalSymbol } from "react-icons/fa";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { MdOutlineUpdate } from "react-icons/md";

const counter_data = [
  {
    count: "8+",
    text: "Years",
  },
  {
    count: "200+",
    text: "Hospitals/Clinics",
  },
  {
    count: "70+",
    text: "Members Team",
  },
  {
    count: "200%",
    text: "Avg ROI",
  },
];

export default function VideoMarketing() {
  return (
    <>
      <Helmet>
        <title>Content Marketing for Healthcare at Health Pioneers</title>
      </Helmet>
      <div className="project-detail-container">
        <Hero title="Digital Signage Solution" />
        <Description />
        <Stats data={counter_data} />
        <FAQSection />
        <Testimonials />
        <OurPartners />
      </div>
    </>
  );
}

function Stats({ data }) {
  return (
    <>
      <section className="counter-section dark">
        <div className="container">
          <div className="row mx-1" style={{ justifyContent: "center" }}>
            {data.map((e, i) => (
              <div
                className="col-6 col-md-3"
                key={i}
                style={{ textAlign: "center" }}
              >
                <h2 className="display-1">{e.count}</h2>
                <p className="txt-1">{e.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

const TableData = () => {
  return (
    <div class="container py-5">
      <div className="row tableDiv">
        <div className="section-tag mb-8 text-center mb-3">
        The Anatomy of Effective Healthcare Content
        </div>
        <div
          className="col-md-12 p-0"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
        >
          <div class="brze_statistike">
            <table className="table table-striped table-bordered text-center m-0">
              <thead>
                <tr>
                  <th scope="col" className="handleResponsive">
                    #
                  </th>
                  <th scope="col">Services</th>
                  <th scope="col">Impact on Healthcare Marketing</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <MdSettingsSuggest color="#e94057" />
                  </th>
                  <td>
                    <span>Tailored Content Strategies</span>
                  </td>
                  <td>Enhanced Patient Trust</td>
                </tr>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <BiBookContent />
                  </th>
                  <td>
                    <span>SEO-Optimized Health Articles</span>
                  </td>
                  <td>Higher Search Engine Rankings</td>
                </tr>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <MdOutlineOndemandVideo color="#e94057" />
                  </th>
                  <td>
                    <span>Informative Video Content</span>
                  </td>
                  <td>Improved Patient Engagement</td>
                </tr>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <FaHospitalSymbol color="#e94057" />
                  </th>
                  <td>
                    <span>Patient Testimonial Management</span>
                  </td>
                  <td>Boosted Healthcare Credibility</td>
                </tr>
                <tr>
                  <th scope="row" className="handleResponsive">
                    <MdOutlineUpdate  color="#e94057" />
                  </th>
                  <td>
                    <span>Regular Content Updates & Monitoring</span>
                  </td>
                  <td>Consistent Online Presence</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function OurPartners() {
  return (
    <>
      <section className="ourParnersSection">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
              <div className="section-tag mb-8">Our Partners</div>
              {/* <h2>Our Technology Partners</h2> */}
            </div>
          </div>
          <div className="d-flex gap-5 align-items-center justify-content-center ourParners text-center flex-wrap">
            <div>
              <FaGoogle className="google" fontSize={48} />
              <p>Google Partners</p>
            </div>
            <div>
              <TbBrandMeta className="meta" fontSize={48} />
              <p>Meta Partners</p>
            </div>
            <div>
              <FaInstagram className="instagram" fontSize={48} />
              <p>Instagram Partners</p>
            </div>
            <div>
              <SiMicrosoftbing className="bing" fontSize={48} />
              <p>Bing Partners</p>
            </div>
            <div>
              <BsYoutube className="youtube" fontSize={48} />
              <p>Youtube Ads</p>
            </div>
            <div>
              <BsFacebook className="facebook" fontSize={48} />
              <p>Facebook Ads</p>
            </div>
            <div>
              <BsTwitter className="twitter" fontSize={48} />
              <p>Twitter Ads</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function FAQSection() {
  const FAQ_data = [
    {
      title: "How often do you recommend updating healthcare content?",
      text: `Regular updates are crucial. We suggest a monthly review and quarterly comprehensive updates.`,
    },
    {
      title: "Can content marketing improve my clinic's search engine ranking?",
      text: "Absolutely! SEO-optimized content is key to better search rankings.",
    },
  ];

  return (
    <>
      <section className="section-global bg-shade-green" id="FAQs">
        <div className="container">
          <div className="row">
            <div className="row mb-40">
              <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                <div className="section-tag mb-8">FAQs</div>
                <h2>Do You Have Questions?</h2>
              </div>
            </div>
          </div>
          <div className="container container-2">
            <div className="row">
              <div className="col-12">
                <Accordion alwaysOpen flush>
                  {FAQ_data.map((e, i) => (
                    <Accordion.Item eventKey={i} key={i}>
                      <Accordion.Header>{e.title}</Accordion.Header>
                      <Accordion.Body>{e.text}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const Hero = () => {
  return (
    <>
      <section className="section-global no-border bg-shade-green hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Elevate Your Healthcare Message with HealthPioneers</h1>
            </div>
            <div className="col-lg-6">
              <p className="txt-1 mb-20" style={{ lineHeight: "2" }}>
                In the healthcare industry, connecting with your audience
                requires precision, empathy, and expertise. HealthPioneers
                brings you a blend of digital marketing prowess and deep
                healthcare insights. With us, your content isn't just
                informative – it's transformative.
              </p>
              <Link to="/" className="btn btn-outline-primary btn-lg">
                Know more
              </Link>
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div className="col-12">
              <div className="hero-image-container">
                <img
                  style={{
                    height: "680px",
                    borderRadius: "15px",
                    boxShadow:
                      "0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal)",
                    objectFit: "cover",
                    objectPosition: "center",
                    width: "100%",
                  }}
                  loading="lazy"
                  src={require("./images/content marketing for healthcare banner.png")}
                  className="img-fluid"
                  alt="Stact"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <BrandSection2
                brands={brands}
                bg="bg-shade-green"
                light
                src={"OurSpeciality"}
            /> */}
    </>
  );
};

const Description = () => {
  return (
    <section
      className="section-global bg-shade-blue"
      style={{ paddingBottom: "50px" }}
    >
      <div className="container">
        <div className="row mb-40 gy-4">
          <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
            <div>
              <img
                loading="lazy"
                src={require("./images/Why HealthPioneers for Content Marketing.png")}
                className="img-fluid"
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="section-tag mb-8">Branding</div>
            <h2 className="mb-16">Why HealthPioneers for Content Marketing?</h2>
            <p className="txt-1">
              In the intersection of healthcare and digital marketing,
              HealthPioneers emerges as a beacon of excellence. Our dedication
              goes beyond mere content creation; we craft narratives that
              resonate, inform, and engage. Our strategies, rooted in a
              patient-centric approach and evidence-based content, are designed
              to cater to the specific needs of the healthcare sector. Our track
              record, evident in results like the 3x surge in inquiries for the
              City-based Multi-speciality Hospital, speaks volumes.
            </p>
          </div>
        </div>
        <div className="row mb-40 gy-4 custom-div-handle">
          <div className="col-lg-6 align-self-center">
            <div className="section-tag mb-8">Branding</div>
            <h2 className="mb-16">Process for Content Marketing:</h2>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>
                Patient-Centric Approach:{" "}
              </span>{" "}
              Understanding the needs and concerns of patients to create
              relatable content.
            </p>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>Evidence-Based Content:</span>{" "}
              Every piece of information is backed by credible sources.
            </p>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>Engagement Analytics: </span>
              Keeping track of content performance to refine strategies.
            </p>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>
                {" "}
                Multi-platform Distribution:{" "}
              </span>{" "}
              Ensuring content reaches audiences wherever they are.
            </p>
            <p className="txt-1">
              <span style={{ fontWeight: "600" }}>Feedback Integration:</span>{" "}
              Continuously improving based on feedback from healthcare
              professionals and patients.
            </p>
          </div>
          <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
            <div>
              <img
                loading="lazy"
                src={require("./images/Process for Content Marketing.png")}
                className="img-fluid"
                alt="About"
              />
            </div>
          </div>
        </div>
        <TableData />
      </div>
    </section>
  );
};

function Testimonials() {
  const testimonial_data = [
    {
      text: "Branding Pioneers is hands down the best digital marketing agency we've ever worked with. Their team is incredibly knowledgeable and they were able to help us achieve our goals in a fraction of the time we expected.",
      userName: "Vandana Choudhary",
      position: "Client",
      userImgUrl: "hari.jpg",
    },
    {
      text: "Highly recommended !! Had a very good experience with Branding pioneers. They have completed the project in the said time frame and kept all the promises.",
      userName: "Nishant Raghav",
      position: "Marketing Director at ABC Hospital",
      userImgUrl: "manish.jpg",
    },
    {
      text: "The team at Branding Pioneers has been instrumental in helping us establish a strong brand identity and an effective digital marketing strategy. Their attention to detail and commitment to our success is second to none. Highly recommend!",
      userName: "Roki Sahu",
      position: "Client",
      userImgUrl: "jasleenkaur.jpg",
    },
  ];

  return (
    <>
      <div className="section-global bg-shade-blue">
        <div className="container">
          <div className="row mb-40 justify-content-between gy-4">
            <div className="col-xl-5 col-lg-5">
              <div className="section-tag mb-8 ">Customer reviews</div>
              <h2>Adopted by the most creative individuals</h2>
            </div>
            <div className="col d-flex align-self-center">
              <Link
                to="about/testimonials"
                className="btn btn-outline btn-arrow ms-lg-auto"
              >
                See all reviews
                <TbArrowNarrowRight fontSize={24} />
              </Link>
            </div>
          </div>
          <div className="row">
            <TestimonialCards data={testimonial_data} />
          </div>
        </div>
      </div>
    </>
  );
}
