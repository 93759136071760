import { Link } from "react-router-dom";
import TestimonialCards from "../../components/testimonialCards";
import { TbArrowNarrowRight, TbBrandMeta } from "react-icons/tb";
import "./style.scss";
import Stats from "../../components/stats";
import { Accordion } from "react-bootstrap";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { SiMicrosoftbing } from "react-icons/si";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { MdSettingsSuggest } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
import { BsGoogle } from "react-icons/bs";
import { FaHospitalSymbol } from "react-icons/fa";
import { Helmet } from "react-helmet";

export default function SEO() {
    const counter_data = [
        {
            count: "8+",
            text: "Years",
        },
        {
            count: "200+",
            text: "Hospitals/Clinics",
        },
        {
            count: "70+",
            text: "Members Team",
        },
        {
            count: "200%",
            text: "Avg ROI",
        },
    ];
    return (
        <>
            <Helmet>
                <title>SEO Marketing at Health Pioneers</title>
            </Helmet>
            <div className="project-detail-container">
                <Hero title="Hospitals" />
                <Description />
                {/* <CaseStudy /> */}
                <Stats data={counter_data} />
                <FAQSection />
                <Testimonials />
                <OurPartners />
            </div>
        </>
    );
}


const TableData = () => {
    return (
        <div class="container py-5">
            <div className="row tableDiv">
                <div className="section-tag mb-8 text-center mb-3">
                    Our Comprehensive Healthcare SEO Services
                </div>
                <div
                    className="col-md-12 p-0"
                    style={{
                        boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}>
                    <div class="brze_statistike">
                        <table className="table table-striped table-bordered text-center m-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="handleResponsive">
                                        #
                                    </th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Benefits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <MdSettingsSuggest color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Keyword Analysis</span>
                                    </td>
                                    <td>
                                        Researching healthcare-specific terms.
                                    </td>
                                    <td>Improved search visibility</td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <BiBookContent />
                                    </th>
                                    <td>
                                        <span>Content Optimization</span>
                                    </td>
                                    <td>
                                        Crafting medical content for search
                                        engines.
                                    </td>
                                    <td>70% increase in organic traffic</td>
                                </tr>
                                <tr>
                                    <th
                                        scope="row"
                                        className="handleResponsive">
                                        <BsGoogle color="#e94057" />
                                    </th>
                                    <td>
                                        <span>Technical SEO</span>
                                    </td>
                                    <td>
                                        On-site enhancements for speed and
                                        efficiency.
                                    </td>
                                    <td>40% reduced bounce rate</td>
                                </tr>
                                <tr>
                                    <th className="handleResponsive">
                                        <FaHospitalSymbol />
                                    </th>
                                    <td>
                                        <span>Local SEO</span>
                                    </td>
                                    <td>
                                        Ensuring patients nearby can find you.
                                    </td>
                                    <td>
                                        55% increase in local patient inquiries
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

function OurPartners() {
    return (
        <>
            <section className="ourParnersSection">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Our Partners</div>
                            {/* <h2>Our Technology Partners</h2> */}
                        </div>
                    </div>
                    <div className="d-flex gap-5 align-items-center justify-content-center ourParners text-center flex-wrap">
                        <div>
                            <FaGoogle className="google" fontSize={48} />
                            <p>Google Partners</p>
                        </div>
                        <div>
                            <TbBrandMeta className="meta" fontSize={48} />
                            <p>Meta Partners</p>
                        </div>
                        <div>
                            <FaInstagram className="instagram" fontSize={48} />
                            <p>Instagram Partners</p>
                        </div>
                        <div>
                            <SiMicrosoftbing className="bing" fontSize={48} />
                            <p>Bing Partners</p>
                        </div>
                        <div>
                            <BsYoutube className="youtube" fontSize={48} />
                            <p>Youtube Ads</p>
                        </div>
                        <div>
                            <BsFacebook className="facebook" fontSize={48} />
                            <p>Facebook Ads</p>
                        </div>
                        <div>
                            <BsTwitter className="twitter" fontSize={48} />
                            <p>Twitter Ads</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function FAQSection() {
    const FAQ_data = [
        {
            title: "How soon can I see results from the SEO efforts?",
            text: `SEO is a long-term strategy. While some changes yield quick results, substantial growth usually takes 4-6 months.`,
        },
        {
            title: "How do you tailor your strategies to the medical field?",
            text: "We have extensive experience and research data specific to healthcare, ensuring strategies that resonate with patients.",
        },
        {
            title: "Will I get monthly reports on the performance?",
            text: "Absolutely! We believe in transparent reporting and will provide monthly insights.",
        },
        {
            title: "Can you guarantee specific results for my healthcare business?",
            text: "While we cannot guarantee specific results, we can promise that we will work tirelessly to deliver the best possible outcomes for your healthcare business. Our team of healthcare marketing experts will collaborate with you to develop a customized strategy that aligns with your business goals and objectives. We will regularly measure and analyze the performance of your campaigns to make data-driven decisions and adjust our approach as necessary to optimize your results.",
        },
    ];

    return (
        <>
            <section className="section-global bg-shade-green" id="FAQs">
                <div className="container">
                    <div className="row">
                        <div className="row mb-40">
                            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                                <div className="section-tag mb-8">FAQs</div>
                                <h2>Do You Have Questions?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container container-2">
                        <div className="row">
                            <div className="col-12">
                                <Accordion alwaysOpen flush>
                                    {FAQ_data.map((e, i) => (
                                        <Accordion.Item eventKey={i} key={i}>
                                            <Accordion.Header>
                                                {e.title}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {e.text}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const Hero = () => {
    return (
        <>
            <section className="section-global no-border bg-shade-green hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>
                                Elevate Your Medical Brand with Specialized
                                Healthcare SEO Services
                            </h1>
                        </div>
                        <div className="col-lg-6">
                            <p
                                className="txt-1 mb-20"
                                style={{ lineHeight: "2" }}>
                                In today's digital-centric world, patients often
                                turn to search engines for healthcare
                                information before seeking medical
                                practitioners. Establishing a prominent online
                                presence is crucial for ensuring potential
                                patients discover your healthcare practice
                                first. This is where HealthPioneers, the leading
                                healthcare SEO agency, excels. With expertise in
                                healthcare-specific SEO, we boost your online
                                visibility, helping you stand out in the digital
                                landscape.
                            </p>
                            <Link
                                to="/"
                                className="btn btn-outline-primary btn-lg">
                                Know more
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "50px" }}>
                        <div className="col-12">
                            <div className="hero-image-container">
                                <img
                                    style={{
                                        height: "680px",
                                        borderRadius: "15px",
                                        boxShadow:
                                            "0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal)",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        width: "100%",
                                    }}
                                    loading="lazy"
                                    src={require("./images/SEO.jpg")}
                                    className="img-fluid"
                                    alt="Stact"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <BrandSection2
                brands={brands}
                bg="bg-shade-green"
                light
                src={"OurSpeciality"}
            /> */}
        </>
    );
};

const Description = () => {
    return (
        <section
            className="section-global bg-shade-blue"
            style={{ paddingBottom: "50px" }}>
            <div className="container">
                <div className="row mb-40 gy-4">
                    <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                        <div>
                            <img
                                loading="lazy"
                                src={require("./images/Why Choose HealthPioneers for Healthcare SEO.png")}
                                className="img-fluid"
                                alt="About"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="section-tag mb-8">Branding</div>
                        <h2 className="mb-16">
                            Why Choose HealthPioneers for Healthcare SEO?
                        </h2>
                        <p className="txt-1">
                            <span style={{ fontWeight: "600" }}>
                                Experience and Expertise:
                            </span>{" "}
                            Years dedicated to mastering SEO in the healthcare
                            realm.
                        </p>
                        <p className="txt-1">
                            <span style={{ fontWeight: "600" }}>
                                Customized Strategies:
                            </span>{" "}
                            SEO solutions tailored to specific medical
                            practices. Transparent Reporting: Witness your rise
                            in search engine rankings and increased patient
                            engagements.
                        </p>
                        <p className="txt-1">
                            <span style={{ fontWeight: "600" }}>
                                Infographic Idea:
                            </span>{" "}
                            "The Journey of a Patient Online" - depicting how
                            patients search, find, and choose their healthcare
                            providers.
                        </p>

                        {/* <ul className="txt-1">
                            <li>
                                Tailored Strategies: Understand your
                                institution's unique challenges and goals. Our
                                strategies are custom-made, ensuring you get the
                                best ROI.
                            </li>
                            <li>
                                Domain Expertise: With a solid background in
                                healthcare, our strategies resonate with your
                                audience, ensuring maximum engagement.
                            </li>
                            <li>
                                Data-Driven Approach: Using advanced analytics
                                and tools, we drive decisions that lead to
                                tangible results.
                            </li>
                            <li>
                                End-to-End Service: From strategy formulation to
                                execution, we've got you covered. Infographic
                                Idea: Journey of a hospital in the digital
                                domain - from initial presence to dominant
                                leadership with HealthPioneers.
                            </li>
                        </ul> */}
                    </div>
                </div>
                <TableData />
            </div>
        </section>
    );
};

function Testimonials() {
    const testimonial_data = [
        {
            text: "Branding Pioneers is hands down the best digital marketing agency we've ever worked with. Their team is incredibly knowledgeable and they were able to help us achieve our goals in a fraction of the time we expected.",
            userName: "Vandana Choudhary",
            position: "Client",
            userImgUrl: "hari.jpg",
        },
        {
            text: "Highly recommended !! Had a very good experience with Branding pioneers. They have completed the project in the said time frame and kept all the promises.",
            userName: "Nishant Raghav",
            position: "Marketing Director at ABC Hospital",
            userImgUrl: "manish.jpg",
        },
        {
            text: "The team at Branding Pioneers has been instrumental in helping us establish a strong brand identity and an effective digital marketing strategy. Their attention to detail and commitment to our success is second to none. Highly recommend!",
            userName: "Roki Sahu",
            position: "Client",
            userImgUrl: "jasleenkaur.jpg",
        },
    ];

    return (
        <>
            <div className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8 ">
                                Customer reviews
                            </div>
                            <h2>Adopted by the most creative individuals</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link
                                to="about/testimonials"
                                className="btn btn-outline btn-arrow ms-lg-auto">
                                See all reviews
                                <TbArrowNarrowRight fontSize={24} />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} />
                    </div>
                </div>
            </div>
        </>
    );
}

// const CaseStudy = () => {
//     return (
//         <>
//             <section className="section-global case-study">
//                 <div className="container container-2">
//                     <div className="row">
//                         <div className="col-12 text-center mb-20">
//                             {/* <img loading='lazy' src="" className="img-fluid mb-20" alt="CaseStudy" /> */}
//                         </div>
//                         <div className="col-12">
//                             <div className="case-study-section">
//                                 <p className="txt-1">
//                                     Search Engine Optimization (SEO) is a
//                                     crucial part of any digital marketing
//                                     strategy. It's a set of techniques used to
//                                     optimize your website for search engines
//                                     like Google, Bing, and Yahoo, to improve its
//                                     ranking and visibility in search engine
//                                     results pages (SERPs). At Branding Pioneers,
//                                     we offer a comprehensive range of SEO
//                                     services to help businesses achieve their
//                                     online goals. Here's what we can do for you:
//                                 </p>
//                                 <ol>
//                                     <li className="txt-1">
//                                         On-page optimization: We'll optimize
//                                         your website's structure, content, and
//                                         HTML code to make it more search
//                                         engine-friendly. This includes keyword
//                                         research, meta tag optimization, content
//                                         optimization, internal linking, and
//                                         more.
//                                     </li>
//                                     <li className="txt-1">
//                                         Off-page optimization: We'll build
//                                         high-quality backlinks to your website
//                                         from other relevant sites to improve its
//                                         authority and credibility in the eyes of
//                                         search engines. This includes guest
//                                         blogging, social media marketing,
//                                         influencer outreach, and more.
//                                     </li>
//                                     <li className="txt-1">
//                                         Technical optimization: We'll ensure
//                                         that your website's technical aspects,
//                                         such as page speed, mobile-friendliness,
//                                         security, and site structure, are
//                                         optimized for search engines.
//                                     </li>
//                                     <li className="txt-1">
//                                         Reporting and analysis: We'll provide
//                                         you with regular reports on your
//                                         website's performance and use data
//                                         analytics to refine our SEO strategy and
//                                         maximize your ROI.
//                                     </li>
//                                 </ol>
//                                 <br />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };
